import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { cardColors } from './BookingCard';

const CreateBookingCard: FunctionComponent<{ order: number; onClick?: () => void }> = ({
  order,
  onClick,
}) => {
  return (
    <Box
      onClick={onClick}
      role="listitem"
      flexGrow={1}
      sx={{
        padding: '10px',
        margin: '10px',
        color: '#FFFFFF',
        backgroundColor: cardColors[(order ?? 1) % cardColors.length],
        boxShadow: '3px 3px #CDCDCD',
        borderRadius: '10px',
        height: 'calc(100% - 40px)',
      }}
    >
      <Box display={'flex'} alignItems={'center'} textAlign={'center'} height={'100%'}>
        <Box margin={'auto'}>
          <Box padding={'5px'}>
            <FontAwesomeIcon size="4x" icon={faPlusCircle} />
          </Box>
          <Typography>Add a new Booking</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateBookingCard;
