import { Control, FieldValues, Path } from 'react-hook-form';
import { autocompleteHotelLocationsByBrand } from '../../providers/hotelpricewatch/client';
import FormAutocomplete from './FormAutocomplete';
import { HOTEL_CHAINS } from '../../providers/hotelpricewatch/types';

function getAutocompleteFn(chain: HOTEL_CHAINS) {
  return async function (searchString?: string, updatedAbortController?: AbortController) {
    return autocompleteHotelLocationsByBrand(chain, searchString, 1, updatedAbortController).then(
      (hotels) =>
        hotels.map<{ label: string; value: string; caption?: string }>((hotel) => ({
          label: hotel.displayName,
          value: hotel.id,
          caption: hotel.address,
        }))
    );
  };
}

const HotelLocationFormAutocomplete = <R extends FieldValues>({
  name,
  control,
  label,
  chain,
}: {
  name: Path<R>;
  control: Control<R>;
  label: string;
  chain: HOTEL_CHAINS;
}) => {
  return (
    <FormAutocomplete
      control={control}
      label={label}
      name={name}
      optionsFetchFn={getAutocompleteFn(chain)}
    />
  );
};
export default HotelLocationFormAutocomplete;
