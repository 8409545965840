import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { HotelBooking } from '../providers/hotelpricewatch/types';
import { getPublicDashboard } from '../providers/hotelpricewatch/client';
import { differenceInWeeks } from 'date-fns';

interface PublicDashboardState {
  sampleBookings: HotelBooking[];
  fetchedDate?: Date;
  initializeSampleBookings: () => Promise<void>;
}

export const usePublicDashboardStore = create<PublicDashboardState>()(
  devtools(
    persist(
      (set, get) => {
        return {
          sampleBookings: [],
          initializeSampleBookings: async () => {
            const { sampleBookings: currentSampleBookings, fetchedDate } = get();

            if (
              !currentSampleBookings.length ||
              !fetchedDate ||
              differenceInWeeks(new Date(), new Date(fetchedDate)) >= 1
            ) {
              getPublicDashboard().then((sampleBookings) => {
                set({ sampleBookings, fetchedDate: new Date() });
              });
            }
          },
        };
      },
      {
        name: 'publicDashboard',
      }
    )
  )
);
