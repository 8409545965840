import { FunctionComponent, useEffect } from 'react';
import { Container, Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { removeUtms } from '../utils/common';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import { AUTH_STATE, useAuthStore } from '../state/authentication';
import { useModalStateStore } from '../state/modals';
import SampleDashboard from './SampleDashboard';
import LoadingSpinner from '../components/LoadingSpinner';
import UserDashboard from './UserDashboard';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const Dashboard: FunctionComponent = () => {
  const { authState } = useAuthStore(({ authState }) => ({ authState }));
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title: 'Landing Page',
      event_callback: removeUtms,
    });
  }, []);

  if (authState === AUTH_STATE.UNAUTHENTICATED) return <SampleDashboard />;
  if (authState === AUTH_STATE.UNKNOWN) return <LoadingSpinner />;

  return <UserDashboard />;
};

export default Dashboard;
