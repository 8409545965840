export type User = {
  id: string;
};

export enum HOTEL_CHAINS {
  MARRIOTT = 'MARRIOTT',
}

export enum HotelBookingStatus {
  WAITING_FOR_DETAILS = 'WAITING_FOR_DETAILS',
  EMAIL_RECIEVED = 'EMAIL_RECIEVED',
  EMAIL_PROCESSED = 'EMAIL_PROCESSED',
  ERROR = 'ERROR',
}

type HotelBookingMetadata = {
  emailEndpoint?: {
    address: string;
    expiry: number; // UTC epoch timestamp
  };
};

export type PriceCheckMetadata = Record<string, unknown>;

export type HotelLocation = {
  id: string;
  hotelChainId?: string;
  displayName: string;
  address: string;
  chainParameters: Record<string, never>;
  metadata: Record<string, never>;
  updatedAt: Date;
  createdAt: Date;
};

export type PriceCheck = {
  id: string;
  hotelBookingId?: string;
  bookingType: 'HOTEL';
  totalPrice: number;
  currency: string;
  metadata: PriceCheckMetadata;
  createdAt: Date;
};

export type HotelBooking = {
  id: string;
  hotelLocationId: string;
  userId: string;
  displayName: string;
  status: HotelBookingStatus;
  checkInDate?: Date;
  checkOutDate?: Date;
  currency?: string;
  metadata: HotelBookingMetadata;
  createdAt: Date;

  hotel?: HotelLocation;
  priceChecks?: PriceCheck[];
};

export type Pagination = {
  limit: number;
  page: number;
  sort_by?: string;
  sort_order: 'ascending' | 'descending';
  totalRows: number;
};

export type GetHotelBookingsesponse = {
  pagination: Pagination;
  results: Array<HotelBooking>;
};

export type PostNewBookingRequestPayload = {
  hotelBrand?: string;
  hotelLocationId?: string;
  hotelName?: string;
  hotelAddress?: string;
  hotelRoomDescriptor: {
    roomName?: string;
    rateName?: string;
  };

  checkInDate: string;
  checkOutDate: string;
  adultsCount: number;
  roomsCount: number;
  initialPrice?: number;
  confirmationNumber?: string;
};

export type PostNewBookingResponse = HotelBooking;

export type GetPublicDashboardResponse = HotelBooking[];

export type GetDashboardResponse = { hotelBookings: HotelBooking[] };

export type GetUserResponse = User & { hotelBookings: Exclude<HotelBooking, 'priceChecks'>[] };

export type AutocompleteHotelRoomTypesByHotelIdResponse = {
  roomName: string;
  rateName: string;
}[];
