const translations = {
  title: 'Hotel Price Watch',

  header: {
    menu: {
      buttons: {
        contact: {
          label: 'Contact Us',
        },
        newBooking: {
          label: 'Add New Booking',
        },
      },
    },
  },

  footer: {
    privacyPolicy: 'Privacy Policy',
  },
};

export default translations;
