import { Modal } from '@mui/material';
import { FunctionComponent } from 'react';
import AuthenticationModalBody from './AuthenticationModalBody';
import { useModalStateStore } from '../../../state/modals';

const AuthenticationModal: FunctionComponent<Record<string, never>> = ({}) => {
  const { authModalOpen, closeAuthModal } = useModalStateStore(
    ({ authModalOpen, closeAuthModal }) => ({
      authModalOpen,
      closeAuthModal,
    })
  );

  return (
    <>
      <Modal
        open={authModalOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') closeAuthModal();
        }}
      >
        <AuthenticationModalBody />
      </Modal>
    </>
  );
};

export default AuthenticationModal;
