import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { debounce } from 'lodash';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

const FormAutocomplete = <
  Value extends { label: string; value: string; caption?: string },
  R extends FieldValues,
>({
  name,
  control,
  label,
  disabled,
  optionsFetchFn,
  freeSolo = true,
  disableClearable = true,
  multiple = false,
  ...autoCompleteProps
}: {
  name: Path<R>;
  control: Control<R>;
  label: string;
  disabled?: boolean;
  optionsFetchFn?: (latestInput?: string, abortController?: AbortController) => Promise<Value[]>;
} & Partial<
  AutocompleteProps<Value, boolean | undefined, boolean | undefined, boolean | undefined>
>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange: onChange }, fieldState: { error } }) => {
        const [, setAbortController] = useState<AbortController>(new AbortController());
        const [currentInput, setCurrentInput] = useState('');
        const [options, setOptions] = useState<Value[]>([]);

        const debounceFetch = useMemo(
          () =>
            debounce(
              () => {
                if (optionsFetchFn) {
                  const newAbortController = new AbortController();
                  setAbortController((prev) => {
                    prev.abort();
                    return newAbortController;
                  });
                  optionsFetchFn(currentInput, newAbortController)
                    .then((o) => {
                      setOptions(o);
                    })
                    .catch((e) => Sentry.captureException(e));
                }
              },
              1000,
              {
                leading: false,
                trailing: true,
              }
            ),
          [currentInput]
        );

        useEffect(() => {
          return () => {
            debounceFetch.cancel();
          };
        }, []);

        useEffect(() => {
          debounceFetch();
        }, [currentInput]);

        function onSelect(
          _: SyntheticEvent<Element, Event>,
          newValue: string | Value | (string | Value)[] | null
        ) {
          if (Array.isArray(newValue)) {
            onChange(newValue);
          } else if (newValue && typeof newValue !== 'string') {
            onChange(newValue.value);
          }
        }

        function onInputChange(
          _: SyntheticEvent<Element, Event>,
          newValue: string,
          reason: AutocompleteInputChangeReason
        ) {
          if (reason !== 'input') return;
          setCurrentInput(newValue);
        }

        return (
          <Autocomplete
            {...autoCompleteProps}
            freeSolo={freeSolo}
            disableClearable={disableClearable}
            multiple={multiple}
            filterOptions={(a) => a}
            blurOnSelect
            size="small"
            onChange={onSelect}
            onInputChange={onInputChange}
            onBlur={onBlur}
            disabled={disabled}
            options={options}
            renderInput={(params) => (
              <TextField
                autoComplete="off"
                helperText={error?.message}
                error={!!error}
                label={label}
                {...params}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  <Grid container>
                    <Grid item xs={12}>
                      {option.label}
                    </Grid>
                    {option.caption && (
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">{option.caption}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </li>
              );
            }}
            sx={{ width: '100%', minWidth: { sm: '200px' } }}
          />
        );
      }}
    />
  );
};

export default FormAutocomplete;
