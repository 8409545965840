import { Alert, Container, Grid } from '@mui/material';
import { useModalStateStore } from '../state/modals';
import { useUserStore } from '../state/user';
import { ReactElement, useEffect } from 'react';
import BookingCard from '../components/dashboard/BookingCard';
import CreateBookingCard from '../components/dashboard/CreateBookingCard';

const UserDashboard = () => {
  const { openContactUsModal, openNewBookingModal } = useModalStateStore(
    ({ openContactUsModal, openNewBookingModal }) => ({
      openContactUsModal,
      openNewBookingModal,
    })
  );
  const { hotelBookings, initialize } = useUserStore(({ hotelBookings, initialize }) => ({
    hotelBookings,
    initialize,
  }));

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <Alert severity="info">
        Thank you for taking part in our early access! If you notice any issues or have suggestions
        please use our <a onClick={openContactUsModal}>contact form</a>
      </Alert>
      <Container
        role="list"
        sx={{
          position: 'relative',
          width: { xs: '100%' },
          maxWidth: { xs: '100%' },
          height: '450px',
          margin: 0,
          padding: { xs: 0 },
          zIndex: 0,
        }}
      >
        <Grid container marginX={'auto'}>
          {hotelBookings
            .map<ReactElement>((booking, idx) => (
              <Grid key={booking.id} item xs={12} sm={4} marginX={'auto'}>
                <BookingCard booking={booking} order={idx} />
              </Grid>
            ))
            .concat(
              <Grid marginX={'auto'} key={'BookingCard-addNewBookingCard'} item xs={12} sm={4}>
                <CreateBookingCard onClick={openNewBookingModal} order={hotelBookings.length} />
              </Grid>
            )}
        </Grid>
      </Container>
      <Container
        sx={{
          position: 'relative',
          width: { xs: '100%' },
          maxWidth: { xs: '100%' },
          minHeight: '100px',
          height: 'fit-content',
          margin: 0,
          padding: { xs: 0 },
          overflow: 'hidden',
          marginBottom: { xs: '10px', sm: '80px' },
        }}
      ></Container>
    </>
  );
};

export default UserDashboard;
