import ContactUsModal from './ContactUsModal';
import AuthenticationModal from './authentication/AuthenticationModal';
import CreateBookingModal from './createBooking/CreateBookingModal';

const Modals = () => {
  return (
    <>
      <AuthenticationModal />
      <ContactUsModal />
      <CreateBookingModal />
    </>
  );
};

export default Modals;
