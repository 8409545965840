import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Box, Grid, MenuItem, Skeleton, Typography, useFormControl } from '@mui/material';
import { useEffect, useState } from 'react';

const FormTextField = <
  R extends FieldValues,
  Value extends string | number | Record<string, unknown>,
>({
  name,
  control,
  label,
  disabled,
  items,
  ...selectProps
}: {
  name: Path<R>;
  control: Control<R>;
  items?:
    | { display: string; value: Value; caption?: string }[]
    | Promise<{ display: string; value: Value; caption?: string }[]>;
} & TextFieldProps) => {
  const formControl = useFormControl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [menuItems, setMenuItems] = useState<{ display: string; value: Value; caption?: string }[]>(
    []
  );

  useEffect(() => {
    if (!items) {
      return;
    } else {
      setIsLoading(true);
    }

    if (!('then' in items && 'catch' in items)) {
      setMenuItems(items);
      setIsLoading(false);
    } else {
      items
        .then(setMenuItems)
        .then(() => setIsLoading(false))
        .then(() => setFetchError(null))
        .catch((err: Error) => {
          setFetchError(err.message);
        });
    }
  }, [items]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
        return (
          <TextField
            size="small"
            select
            error={!!error}
            onChange={({ target: { value: newStrigifiedValue } }) =>
              onChange(JSON.parse(newStrigifiedValue))
            }
            onBlur={onBlur}
            value={typeof value !== 'object' ? value || '' : JSON.stringify(value)}
            InputProps={{
              startAdornment: isLoading ? <Skeleton variant="text" width={'100%'} /> : null,
            }}
            sx={{ width: '100%', minWidth: { sm: '200px' } }}
            label={label}
            disabled={disabled || formControl?.disabled || menuItems.length < 1}
            helperText={
              fetchError ? (
                <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{fetchError}</span>
                </Box>
              ) : error?.message ? (
                <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>{error.message}</span>
                </Box>
              ) : (
                <> </>
              )
            }
            {...selectProps}
          >
            {menuItems.map(({ display, value: itemValue, caption }, idx) => (
              <MenuItem
                key={`formselect-menuitem-${idx}-${display}`}
                value={typeof itemValue !== 'object' ? itemValue : JSON.stringify(itemValue)}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                      {display}
                    </Typography>
                  </Grid>
                  {caption && (
                    <Grid item xs={12}>
                      <Typography
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                        whiteSpace={'nowrap'}
                        variant="subtitle1"
                      >
                        {caption}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  );
};

export default FormTextField;
