import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { toast } from 'react-toastify';
import { HotelBooking } from '../providers/hotelpricewatch/types';
import { getUserDashboard } from '../providers/hotelpricewatch/client';

export type UserState = {
  hotelBookings: HotelBooking[];

  initialize(): Promise<void>;
};

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set) => {
        return {
          hotelBookings: [],

          initialize: async () => {
            await Promise.all([
              getUserDashboard()
                .then(({ hotelBookings }) => set({ hotelBookings }))
                .catch((err) => {
                  console.error(err);
                  toast.error(
                    `There was an error while fetching user bookings. Please try again later`,
                    {
                      toastId: 'getUserDashboardError',
                      delay: 4000,
                    }
                  );
                }),
            ]);
          },
        };
      },
      {
        name: 'user',
        partialize: (state) => ({
          hotelBookings: state.hotelBookings,
        }),
      }
    )
  )
);
