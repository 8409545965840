import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, memo } from 'react';

export const ValidationIcon: FunctionComponent<{ isValid: boolean }> = memo(({ isValid }) => (
  <FontAwesomeIcon
    style={{ marginRight: '4px' }}
    color={isValid ? '#038127' : '#FF0000'}
    icon={faXmark}
  />
));
