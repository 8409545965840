import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import * as yup from 'yup';
import { newBookingSchema } from '../components/modals/createBooking/CreateBookingModalBody';

interface PreAuthenticationState {
  newBooking: null | yup.InferType<typeof newBookingSchema>;
  saveNewBooking: (newBooking: yup.InferType<typeof newBookingSchema>) => void;
  clearNewBooking: () => void;
}

export const usePreAuthenticationStore = create<PreAuthenticationState>()(
  devtools(
    persist(
      (set) => ({
        newBooking: null,
        saveNewBooking: (newBooking) => set({ newBooking }),
        clearNewBooking: () => set({ newBooking: null }),
      }),
      {
        name: 'pre-auth',
      }
    )
  )
);
