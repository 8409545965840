import { FunctionComponent, ReactElement, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { removeUtms } from '../utils/common';
import BookingCard from '../components/dashboard/BookingCard';
import { usePublicDashboardStore } from '../state/publicDashboard';
import { useModalStateStore } from '../state/modals';
import { insertAtIndex } from '../helpers/common';
import CreateBookingCard from '../components/dashboard/CreateBookingCard';

const SampleDashboard: FunctionComponent = () => {
  const { openNewBookingModal } = useModalStateStore(
    ({ openContactUsModal, openNewBookingModal }) => ({
      openContactUsModal,
      openNewBookingModal,
    })
  );
  const { sampleBookings, initializeSampleBookings } = usePublicDashboardStore(
    ({ sampleBookings, initializeSampleBookings }) => ({
      sampleBookings,
      initializeSampleBookings,
    })
  );
  const { pathname } = useLocation();

  useEffect(() => {
    initializeSampleBookings();
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title: 'Landing Page',
      event_callback: removeUtms,
    });
  }, []);

  return (
    <>
      <Container
        role="list"
        sx={{
          position: 'relative',
          width: { xs: '100%' },
          maxWidth: { xs: '100%' },
          height: '450px',
          margin: 0,
          padding: { xs: 0 },
          zIndex: 0,
        }}
      >
        <Grid container>
          {insertAtIndex(
            sampleBookings.map<ReactElement>((booking, idx) => (
              <Grid key={booking.id} item xs={12} sm={4} marginX={'auto'}>
                <BookingCard booking={booking} order={idx} />
              </Grid>
            )),
            1,
            <Grid key={'BookingCard-addNewBookingCard'} item xs={12} sm={4} marginX={'auto'}>
              <CreateBookingCard onClick={openNewBookingModal} order={sampleBookings.length} />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SampleDashboard;
