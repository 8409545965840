import { faCalendarDays, faLocationPin, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { HotelBooking, PriceCheck } from '../../providers/hotelpricewatch/types';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const cardColors = ['#2D566B', '#227586', '#871E6A', '#AB1656'];

export type BookingCardBookingProp = Pick<
  Omit<HotelBooking, 'priceChecks'>,
  'checkInDate' | 'checkOutDate' | 'currency' | 'displayName' | 'hotel'
> & {
  priceChecks?: Pick<PriceCheck, 'totalPrice' | 'createdAt'>[];
};

const BookingCard: FunctionComponent<{
  booking: BookingCardBookingProp;
  order: number;
  onClick?: () => void;
}> = ({
  booking: { priceChecks = [], hotel, checkInDate, checkOutDate, currency, displayName },
  order,
  onClick,
}) => {
  const labels = priceChecks.map(({ createdAt }) => format(new Date(createdAt), 'MM-dd'));
  const data = priceChecks.map(({ totalPrice }) => totalPrice);

  return (
    <Box
      onClick={onClick}
      role="listitem"
      flexGrow={1}
      sx={{
        padding: '10px',
        margin: '10px',
        color: '#FFFFFF',
        backgroundColor: cardColors[(order ?? 1) % cardColors.length],
        boxShadow: '3px 3px #CDCDCD',
        borderRadius: '10px',
      }}
    >
      <Typography
        variant="h2"
        fontWeight={600}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {displayName ? displayName : hotel?.displayName ? hotel?.displayName : 'My Trip'}
      </Typography>
      <Grid container sx={{ paddingLeft: '3px' }}>
        <Grid xs={12} sm={6} item whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          <Typography variant="caption">
            <FontAwesomeIcon
              style={{
                marginRight: '3px',
              }}
              icon={faCalendarDays}
            />
            {checkInDate ? format(utcToZonedTime(new Date(checkInDate), 'UTC'), 'MMM dd') : '??'}-
            {checkOutDate ? format(utcToZonedTime(new Date(checkOutDate), 'UTC'), 'MMM dd') : '??'}
            {/* March 17 - March 23 */}
          </Typography>
        </Grid>
        {hotel?.address && (
          <Grid xs={12} sm={6} item whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            <Typography variant="caption">
              <FontAwesomeIcon
                style={{
                  marginRight: '3px',
                }}
                icon={faLocationPin}
              />
              {hotel.address}
            </Typography>
          </Grid>
        )}
        <Grid xs={12} item whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          <Typography variant="caption">
            <FontAwesomeIcon
              style={{
                marginRight: '3px',
              }}
              icon={faClock}
            />
            Last Checked{' '}
            {priceChecks.length
              ? format(
                  new Date(priceChecks[priceChecks.length - 1].createdAt),
                  'E, MMM d, yyyy kkmm '
                )
              : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Box>
        <Line
          options={{
            responsive: true,
            interaction: {
              intersect: false,
              mode: 'point',
            },
            plugins: {
              title: {
                display: true,
                text: 'Title',
              },
              tooltip: {
                position: 'nearest',
                displayColors: false,
                callbacks: {
                  title: function () {
                    return '';
                  },
                  label: function ({ label, formattedValue }) {
                    return `${label}: ${currency ? `${currency} ` : ''}${formattedValue}`;
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {},
                display: false,
                grid: {
                  display: false,
                },
              },
              y: {
                display: true,
                ticks: {
                  color: '#FFF',
                },
                grid: {
                  display: true,
                },
              },
            },
          }}
          data={{
            labels: labels,
            datasets: [{ label: 'Prices', borderColor: '#FFF', data: data }],
          }}
        />
      </Box>
    </Box>
  );
};

export default BookingCard;
