import { Amplify } from 'aws-amplify';
import {
  SignInOutput,
  SignUpOutput,
  confirmResetPassword,
  confirmSignIn,
  confirmSignUp,
  fetchAuthSession,
  resendSignUpCode,
  resetPassword,
  signIn,
  signInWithRedirect,
  signOut,
  signUp,
} from '@aws-amplify/auth';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { USER_POOL_ID, USER_POOL_CLIENT_ID } from '../constants/common';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: USER_POOL_CLIENT_ID || '',
      userPoolId: USER_POOL_ID || '',

      loginWith: {
        oauth: {
          domain: 'auth.dev.rentalrecords.com',
          redirectSignIn: ['https://app.dev.rentalrecords.com/oauth/signin'],
          redirectSignOut: ['https://app.dev.rentalrecords.com/oauth/signout'],
          scopes: ['openid', 'profile', 'aws.cognito.signin.user.admin', 'email'],
          responseType: 'code',
        },
      },
    },
  },
});

export enum AUTH_STATE {
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',

  UNKNOWN = 'UNKNOWN',
}

export enum AUTH_ERROR {
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  TooManyRequestsException = 'TooManyRequestsException',
  UserNotConfirmedException = 'UserNotConfirmedException',

  OtherException = 'OtherException',
}

export enum AUTH_NEXT_STEPS {
  CONFIRM_SIGN_IN_WITH_TOTP_CODE = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
  CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
  CONTINUE_SIGN_IN_WITH_TOTP_SETUP = 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
  CONTINUE_SIGN_IN_WITH_MFA_SELECTION = 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  DONE = 'DONE',
}

export class AuthError extends Error {
  type = 'AuthError';

  public code: AUTH_ERROR;

  constructor(message: string, code: AUTH_ERROR) {
    super(message);
    this.code = code;
  }
}

export enum SUPPORTED_THIRD_PARTY_PROVIDERS {
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
}

export type AuthState = {
  init(): Promise<void>;

  authState: AUTH_STATE;
  rememberedUsername: string | null;

  login(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<{ session: SignInOutput; nextStep: AUTH_NEXT_STEPS } | void>;
  completeNewPassword(newPassword: string): Promise<void>;
  register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    recaptchaToken: string
  ): Promise<SignUpOutput>;
  verifyAccount(username: string, code: string): Promise<void>;
  resendVerificationCode(username: string): Promise<void>;
  thirdPartySignon(provider: SUPPORTED_THIRD_PARTY_PROVIDERS): Promise<void>;
  completeThirdPartySignon(): Promise<void>;
  logout(global?: boolean): Promise<void>;
  forgotPassword(email: string): Promise<void>;
  forgotPasswordSubmit(email: string, code: string, newPassword: string): Promise<void>;
};

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set, get) => {
        return {
          async init(): Promise<void> {
            if (get().authState !== AUTH_STATE.UNKNOWN) return;

            return fetchAuthSession()
              .then<void>((session) => {
                if (!session.tokens?.accessToken.payload) throw new Error('No User');
                else set({ authState: AUTH_STATE.AUTHENTICATED });
              })
              .catch<void>(() => set({ authState: AUTH_STATE.UNAUTHENTICATED }));
          },
          async getAccessToken(): Promise<string> {
            return fetchAuthSession().then((session) => {
              const accessToken = session.tokens?.accessToken.toString();

              if (!accessToken) throw new Error('No Token');
              else return accessToken;
            });
          },

          authState: AUTH_STATE.UNKNOWN,
          rememberedUsername: null,

          /**
           *
           * @param { string } email
           * @param { string } password
           * @param { boolean } rememberMe
           */
          async login(email: string, password: string, rememberMe: boolean) {
            return await signIn({ username: email, password })
              .then<{ session: SignInOutput; nextStep: AUTH_NEXT_STEPS } | void>(
                (session: SignInOutput) => {
                  if (!session.isSignedIn) {
                    if (session.nextStep.signInStep !== AUTH_NEXT_STEPS.DONE) {
                      return { session, nextStep: session.nextStep.signInStep as AUTH_NEXT_STEPS };
                    }
                  }

                  return fetchAuthSession()
                    .then((session) => {
                      const accessToken = session.tokens?.accessToken.toString();

                      if (accessToken)
                        set({
                          authState: AUTH_STATE.AUTHENTICATED,
                          rememberedUsername: rememberMe ? email : null,
                        });
                      else throw new Error('Unable to get token');
                    })
                    .catch(() => get().logout());
                }
              )
              .catch((error) => {
                const {
                  code = AUTH_ERROR.OtherException,
                  message = 'There was an unknown error logging in',
                }: { code: AUTH_ERROR | undefined; message: string | undefined } = error;

                throw new AuthError(message, code);
              });
          },

          /**
           *
           * @param { string } oldPassword
           * @param { string } newPassword
           */
          async completeNewPassword(newPassword: string) {
            confirmSignIn({ challengeResponse: newPassword });
          },

          /**
           *
           * @param { boolean } global
           */
          async logout(global: boolean = false) {
            await signOut({ global }).finally(() => set({ authState: AUTH_STATE.UNAUTHENTICATED }));
          },

          /**
           *
           * @param email
           * @param password
           * @param firstName
           * @param lastName
           * @param turnstileToken
           * @returns
           */
          async register(
            email: string,
            password: string,
            firstName: string,
            lastName: string,
            recaptchaToken: string
          ): Promise<SignUpOutput> {
            return signUp({
              username: email,
              password,
              options: {
                userAttributes: {
                  given_name: firstName,
                  family_name: lastName,
                },
                autoSignIn: {
                  enabled: false,
                },
                validationData: {
                  recaptchaToken,
                },
              },
            });
          },

          /**
           *
           * @param username
           * @param code
           */
          async verifyAccount(username: string, code: string): Promise<void> {
            confirmSignUp({ username, confirmationCode: code });
          },

          /**
           * @param username
           */
          async resendVerificationCode(username: string) {
            resendSignUpCode({ username });
          },

          /**
           *
           * @param provider
           */
          async thirdPartySignon(provider: SUPPORTED_THIRD_PARTY_PROVIDERS) {
            signInWithRedirect({ provider });
          },

          /**
           *
           */
          async completeThirdPartySignon(): Promise<void> {
            try {
              const accessToken = await fetchAuthSession().then(
                ({ tokens }) => tokens?.accessToken.toString()
              );

              if (accessToken)
                set({
                  authState: AUTH_STATE.AUTHENTICATED,
                });
              else throw new Error('No Access Token');
            } catch (e) {
              throw e;
            }
          },

          /**
           *
           * @param email
           */
          async forgotPassword(email: string): Promise<void> {
            await resetPassword({
              username: email,
            });
          },

          /**
           *
           * @param email
           * @param code
           * @param newPassword
           */
          async forgotPasswordSubmit(
            email: string,
            code: string,
            newPassword: string
          ): Promise<void> {
            await confirmResetPassword({
              username: email,
              confirmationCode: code,
              newPassword,
            });
          },
        };
      },
      {
        name: 'auth',
        partialize: (state) => ({
          rememberedUsername: state.rememberedUsername,
        }),
      }
    )
  )
);
