export enum AUTH_STEP {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export type RequiredAuthPageProps = {
  closeModal: () => void;
  changeStep: (newStep: AUTH_STEP) => void;
};

export type AuthPageProps<
  AdditionalProps extends Record<string, unknown> = Record<string, unknown>,
> = RequiredAuthPageProps & AdditionalProps;
