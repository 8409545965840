import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import { useModalStateStore } from '../../../state/modals';
import FormTextField from '../../hookedForm/FormTextField';
import { FieldErrors, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, format } from 'date-fns';
import FormDatePicker from '../../hookedForm/FormDatePicker';
import { forwardRef } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import {
  autocompleteHotelRoomTypesByHotelId,
  postNewBooking,
} from '../../../providers/hotelpricewatch/client';
import { HOTEL_CHAINS } from '../../../providers/hotelpricewatch/types';
import HotelLocationFormAutocomplete from '../../hookedForm/HotelLocationFormAutocomplete';
import FormSelect from '../../hookedForm/FormSelect';
import { AUTH_STATE, useAuthStore } from '../../../state/authentication';
import { usePreAuthenticationStore } from '../../../state/preAuthentication';

export const newBookingSchema = yup.object<{ a: string }>().shape({
  /** Hotel Information */
  hotelChain: yup.mixed<HOTEL_CHAINS>().oneOf(Object.values(HOTEL_CHAINS)).required('Required'),
  hotelLocationId: yup.string().required('Required'),
  hotelRoomDescriptor: yup
    .object<{
      roomName?: string;
      rateName?: string;
    }>({
      roomName: yup.string().optional(),
      rateName: yup.string().optional(),
    })
    .shape({
      roomName: yup.string().optional(),
      rateName: yup.string().optional(),
    })
    .required('Required'),

  /** Stay Information */
  checkInDate: yup.string().required('Required'),
  checkOutDate: yup.string().required('Required'),
  adultsCount: yup
    .number()
    .typeError('Required')
    .required('Required')
    .min(1, 'Must be greater than 0'),
  roomsCount: yup
    .number()
    .typeError('Required')
    .required('Required')
    .min(1, 'Must be greater than 0'),
  initialPrice: yup.number().optional(),
  confirmationNumber: yup.string().optional(),
});

const CreateBookingModalBody = forwardRef(() => {
  const { closeNewBookingModal, openAuthModal } = useModalStateStore(
    ({ closeNewBookingModal, openAuthModal }) => ({
      closeNewBookingModal,
      openAuthModal,
    })
  );
  const authState = useAuthStore(({ authState }) => authState);
  const { saveNewBooking } = usePreAuthenticationStore(({ saveNewBooking }) => ({
    saveNewBooking,
  }));

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<yup.InferType<typeof newBookingSchema>>({
    defaultValues: {
      /** Hotel Information */
      hotelChain: HOTEL_CHAINS.MARRIOTT,
      hotelLocationId: '',
      hotelRoomDescriptor: undefined,

      /** Stay Information */
      checkInDate: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
      checkOutDate: format(addDays(new Date(), 14), 'yyyy-MM-dd'),
      adultsCount: 1,
      roomsCount: 1,
      initialPrice: undefined,
      confirmationNumber: '',
    },
    resolver: yupResolver(newBookingSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  async function handleSaveAndContinue(
    formValues: yup.InferType<typeof newBookingSchema>
  ): Promise<void> {
    if (authState === AUTH_STATE.UNAUTHENTICATED) {
      saveNewBooking(formValues);
      openAuthModal();
      return;
    }

    console.log(formValues);
    toast.info('Submitting', {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      isLoading: true,
      toastId: 'newBookingApiRequest',
    });
    await postNewBooking(formValues)
      .then(() => {
        toast.update('newBookingApiRequest', {
          render: 'Your booking was created successfully',
          autoClose: 2000,
          isLoading: false,
          type: 'success',
        });
        closeNewBookingModal();
      })
      .catch((err) => {
        console.error(err);
        toast.update('newBookingApiRequest', {
          render: 'There was an issue submitting your stay information',
          autoClose: 2000,
          isLoading: false,
          type: 'error',
        });
      });
  }

  function handleSaveError(err: FieldErrors<yup.InferType<typeof newBookingSchema>>) {
    toast.error('There are still required fields missing', {
      autoClose: 2000,
      toastId: 'newBookingMissingFieldsError',
    });

    Sentry.captureEvent({
      message: 'Validation errors while submitting review',
      type: 'transaction',
      tags: Object.entries(err).reduce((acc, [key, { message }]) => {
        return { ...acc, [key]: message || '' };
      }, {}),
    });
  }

  const hotelId = watch('hotelLocationId');

  return (
    <Container
      maxWidth={false}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: {
          xs: '90%',
          sm: '85%',
        },
        height: {
          xs: '90%',
          sm: 'fit-content',
        },
        maxWidth: '850px',
        width: {
          xs: '100%',
          sm: '90%',
          md: '80%',
        },
        overflowY: 'scroll',
        p: {
          xs: '50px 5px',
          sm: '20px 5px',
        },
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: { xs: '0px', sm: '8px' },
        boxShadow: 24,
      }}
    >
      <Button sx={{ position: 'absolute', top: '5px', right: '0' }} onClick={closeNewBookingModal}>
        <FontAwesomeIcon size="2x" icon={faXmark} />
      </Button>
      <Typography variant="h2">Track your hotel booking</Typography>
      <FormControl disabled={isSubmitting} fullWidth>
        <Container component="section">
          <Alert severity="info" sx={{ my: '5px' }}>
            Currently hotel details need to be entered manually. In the future we'll be streamlining
            this process.
          </Alert>
          <Typography variant="h3">Hotel Information</Typography>
          <Grid container mt={0} spacing={'10px'}>
            <Grid item xs={12} sm={6} md={4}>
              <HotelLocationFormAutocomplete
                control={control}
                label={'Hotel (search by name or address)'}
                name={'hotelLocationId'}
                chain={watch('hotelChain')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormSelect
                control={control}
                label={'Room Type (Ex. Junior Suite, 1 King, Water view)'}
                name={'hotelRoomDescriptor'}
                items={
                  hotelId
                    ? autocompleteHotelRoomTypesByHotelId(hotelId).then(
                        (rooms) =>
                          rooms?.map((room) => ({
                            display: room.roomName,
                            caption: room.rateName,
                            value: room,
                          }))
                      )
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Container>

        <Divider sx={{ my: '15px' }} />

        <Container component="section">
          <Typography variant="h3">Stay Information</Typography>
          <Grid container mt={0} spacing={'10px'}>
            <Grid item xs={6} sm={6} md={4}>
              <FormDatePicker control={control} label={'Check-In'} name={'checkInDate'} />
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <FormDatePicker control={control} label={'Check-Out'} name={'checkOutDate'} />
            </Grid>

            <Grid item xs={6} md={4}>
              <FormTextField
                type="number"
                control={control}
                label={'Number of Adults'}
                name={'adultsCount'}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <FormTextField
                type="number"
                control={control}
                label={'Number of Rooms'}
                name={'roomsCount'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormTextField
                type="number"
                control={control}
                label={'Initial Price'}
                name={'initialPrice'}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormTextField
                type="number"
                control={control}
                label={'Confirmation Number'}
                name={'confirmationNumber'}
              />
            </Grid>
          </Grid>
        </Container>
        <Box mt={'25px'} width={'100%'} display={'flex'}>
          <Button
            disableRipple
            sx={{ ml: 'auto', width: { xs: '100%', sm: '200px' } }}
            variant="contained"
            onClick={handleSubmit(handleSaveAndContinue, handleSaveError)}
          >
            Save
          </Button>
        </Box>
      </FormControl>
    </Container>
  );
});

export default CreateBookingModalBody;
