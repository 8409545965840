import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  GetUserResponse,
  GetPublicDashboardResponse,
  GetDashboardResponse,
  PostNewBookingResponse,
  PostNewBookingRequestPayload,
  HotelLocation,
  HOTEL_CHAINS,
  Pagination,
  AutocompleteHotelRoomTypesByHotelIdResponse,
} from './types';
import { fetchAuthSession } from '@aws-amplify/auth';
import { AUTH_STATE, useAuthStore } from '../../state/authentication';

async function getAuthHeader(): Promise<{
  Authorization: string;
}> {
  return {
    Authorization: `Bearer ${await fetchAuthSession()
      .then((session) => session.tokens?.accessToken.toString())
      .catch((e) => {
        Sentry.captureException(e);
        useAuthStore.getState().logout();
      })}`,
  };
}

const hotelPricewatchInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
});

export async function getPublicDashboard(): Promise<GetPublicDashboardResponse> {
  return hotelPricewatchInstance
    .request<GetPublicDashboardResponse>({
      url: 'public/dashboard',
      method: 'get',
    })
    .then(({ data }) => data);
}

export async function getUserDashboard(): Promise<GetDashboardResponse> {
  return hotelPricewatchInstance
    .request<GetDashboardResponse>({
      url: 'dashboard',
      method: 'get',
      headers: { ...(await getAuthHeader()) },
    })
    .then(({ data }) => data);
}

export async function getCurrentUser(): Promise<GetUserResponse> {
  return hotelPricewatchInstance
    .request<GetUserResponse>({
      url: 'current-user',
      method: 'get',
      headers: { ...(await getAuthHeader()) },
    })
    .then(({ data }) => data);
}

export async function postNewBooking(
  newBookingRequestPayload: PostNewBookingRequestPayload
): Promise<PostNewBookingResponse> {
  return hotelPricewatchInstance
    .request<PostNewBookingResponse>({
      url: 'hotel-bookings',
      method: 'post',
      headers: { ...(await getAuthHeader()) },
      data: newBookingRequestPayload,
    })
    .then(({ data }) => data);
}

export async function autocompleteHotelLocationsByBrand(
  chain: HOTEL_CHAINS = HOTEL_CHAINS.MARRIOTT,
  search: string = '',
  page: number = 1,

  controller?: AbortController
): Promise<HotelLocation[]> {
  const authState = useAuthStore.getState().authState;
  return hotelPricewatchInstance
    .request<{ data: HotelLocation[]; metadata: Pagination }>({
      url: `${authState === AUTH_STATE.AUTHENTICATED ? '' : 'public/'}search/hotels`,
      method: 'get',
      headers: { ...(authState === AUTH_STATE.AUTHENTICATED ? await getAuthHeader() : {}) },
      params: {
        chain,
        search,
        page,
      },

      signal: controller?.signal,
    })
    .then(({ data: { data } }) => data);
}

export async function autocompleteHotelRoomTypesByHotelId(
  hotelId: string,
  page: number = 1,

  controller?: AbortController
) {
  const authState = useAuthStore.getState().authState;
  return hotelPricewatchInstance
    .request<AutocompleteHotelRoomTypesByHotelIdResponse>({
      url: `${
        authState === AUTH_STATE.AUTHENTICATED ? '' : 'public/'
      }search/hotels/${hotelId}/room-variants`,
      method: 'get',
      headers: { ...(authState === AUTH_STATE.AUTHENTICATED ? await getAuthHeader() : {}) },
      params: {
        page,
      },

      signal: controller?.signal,
    })
    .then(({ data }) => data);
}

/**
 * @function submitContactFormMessage
 *
 * @param { string } recaptchaResponse
 * @param { string } message
 * @param { string | undefined } replyTo
 * @returns { Prmoise<void> }
 */
export async function submitContactFormMessage(
  recaptchaResponse: string,
  message: string,
  topic?: string,
  replyTo?: string
): Promise<void> {
  await hotelPricewatchInstance.request({
    url: 'public/contact',
    method: 'POST',
    headers: {
      recaptcha: recaptchaResponse,
    },
    data: {
      message,
      topic,
      replyTo,
    },
  });
}
