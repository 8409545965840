import { Modal } from '@mui/material';
import { useModalStateStore } from '../../../state/modals';
import CreateBookingModalBody from './CreateBookingModalBody';

const CreateBookingModal = () => {
  const { newBookingModalOpen, closeNewBookingModal } = useModalStateStore(
    ({ newBookingModalOpen, closeNewBookingModal }) => ({
      newBookingModalOpen,
      closeNewBookingModal,
    })
  );

  return (
    <>
      <Modal
        open={newBookingModalOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') closeNewBookingModal();
        }}
      >
        <CreateBookingModalBody />
      </Modal>
    </>
  );
};

export default CreateBookingModal;
