import { ReactNode, forwardRef, useEffect, useState } from 'react';
import { useModalStateStore } from '../../../state/modals';
import { Button, Container } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { AUTH_STEP, RequiredAuthPageProps } from './types';
import LoadingSpinner from '../../LoadingSpinner';
import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import { AUTH_STATE, useAuthStore } from '../../../state/authentication';

const AuthenticationModalBody = forwardRef(() => {
  const [authStep, setAuthStep] = useState<AUTH_STEP>(AUTH_STEP.LOGIN);

  const { closeAuthModal } = useModalStateStore(({ closeAuthModal }) => ({
    closeAuthModal,
  }));
  const { authState } = useAuthStore(({ authState }) => ({ authState }));

  useEffect(() => {
    if (authState === AUTH_STATE.AUTHENTICATED) closeAuthModal();
  }, [authState]);

  const authPageProps: RequiredAuthPageProps = {
    changeStep: setAuthStep,
    closeModal: closeAuthModal,
  };

  let PageComponent: ReactNode;
  if (authStep === AUTH_STEP.LOGIN) {
    PageComponent = <Login {...authPageProps} />;
  } else if (authStep === AUTH_STEP.REGISTER) {
    PageComponent = <Register {...authPageProps} />;
  } else if (authStep === AUTH_STEP.FORGOT_PASSWORD) {
    PageComponent = <ForgotPassword {...authPageProps} />;
  } else {
    PageComponent = (
      <>
        <LoadingSpinner />
      </>
    );
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: {
          xs: '90%',
          sm: '85%',
        },
        height: {
          xs: '90%',
          sm: 'fit-content',
        },
        maxWidth: '850px',
        width: {
          xs: '100%',
          sm: '90%',
          md: '80%',
        },
        overflowY: 'scroll',
        p: {
          xs: '50px 5px',
          sm: '20px 5px',
        },
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: { xs: '0px', sm: '8px' },
        boxShadow: 24,
      }}
    >
      <Button sx={{ position: 'absolute', top: '5px', right: '0' }} onClick={closeAuthModal}>
        <FontAwesomeIcon size="2x" icon={faXmark} />
      </Button>
      {PageComponent}
    </Container>
  );
});

export default AuthenticationModalBody;
