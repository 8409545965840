import { Box, Typography, FormControl, TextField, Button, Select, MenuItem } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { forwardRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useModalStateStore } from '../../state/modals';
import { submitContactFormMessage } from '../../providers/hotelpricewatch/client';

const topics = ['Suggestion', 'Report a Bug/Issue', 'Other'];

const ContactUsModalBody = forwardRef(() => {
  const { closeContactUsModal } = useModalStateStore(({ closeContactUsModal }) => ({
    closeContactUsModal,
  }));

  const [message, setMessage] = useState<string>('');
  const [topic, setTopic] = useState<string>('Suggestion');
  const [email, setEmail] = useState<string>('');
  const [recaptchaResponse, setRecaptchaResponse] = useState<string | null>(null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    setSubmitDisabled(!recaptchaResponse || !message);
  }, [recaptchaResponse, message, email]);

  const submitForm = () => {
    if (!recaptchaResponse) return;

    setFormDisabled(true);
    submitContactFormMessage(recaptchaResponse, message, topic, email)
      .then(closeContactUsModal)
      .catch(() => setFormDisabled(false));
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
          sm: '500px',
        },
        bgcolor: 'background.paper',
        border: 'none',
        borderRadius: '8px',
        boxShadow: 24,
        p: 4,
        minWidth: '305px',
      }}
    >
      <Typography>We&apos;d Love to Hear from You</Typography>

      <FormControl margin="dense" fullWidth disabled={formDisabled}>
        <Select value={topic} onChange={({ target: { value } }) => setTopic(value)}>
          {topics.map((topicValue) => (
            <MenuItem key={`topic-select-${topicValue}`} value={topicValue}>
              {topicValue}
            </MenuItem>
          ))}
        </Select>

        <TextField
          name="Message Input Box"
          margin="dense"
          required
          multiline
          fullWidth
          variant="outlined"
          label="Message"
          onChange={({ target: { value } }) => {
            setMessage(value);
          }}
          value={message}
        />

        <TextField
          name="Optional Email Input Box"
          required={false}
          margin="dense"
          label={'Email (Optional)'}
          onChange={({ target: { value } }) => {
            setEmail(value);
          }}
          value={email}
        />
        <Box margin="auto" height={'80px'}>
          <ErrorBoundary fallback={<Typography>Error Loading ReCaptcha</Typography>}>
            <ReCAPTCHA
              onChange={setRecaptchaResponse}
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || 'MISSING_RECAPTCHA_KEY'}
            />
          </ErrorBoundary>
        </Box>
        <Button onClick={submitForm} disabled={submitDisabled}>
          Submit
        </Button>
      </FormControl>
    </Box>
  );
});

export default ContactUsModalBody;
