import { useFormControl } from '@mui/material';
import {
  DatePicker,
  DatePickerProps,
  DatePickerSlotsComponentsProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enCA from 'date-fns/locale/en-CA';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { merge } from 'lodash';
import { useState } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

const FormDatePicker = <R extends FieldValues>({
  name,
  control,
  disabled,
  slotProps,
  ...datePickerProps
}: {
  name: Path<R>;
  control: Control<R>;
} & DatePickerProps<Date>) => {
  const [datePickerErr, setDatePickerErr] = useState<string | null>(null);

  const formControl = useFormControl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enCA}>
          <DatePicker
            timezone={'UTC'}
            onChange={(date, { validationError }) => {
              let datePickerErrMessage: string | null = null;

              if (validationError) {
                if (validationError === 'minDate')
                  datePickerErrMessage = 'Please select a future date';
                else if (validationError === 'invalidDate')
                  datePickerErrMessage = 'Your date is not valid';
              }

              setDatePickerErr(datePickerErrMessage);

              if (validationError) onChange('');
              else onChange(date ? format(date, 'yyyy-MM-dd') : date);
            }}
            onClose={onBlur}
            value={utcToZonedTime(new Date(value), 'UTC')}
            sx={{
              width: '100%',
              input: {
                paddingY: '8.5px',
              },
            }}
            disabled={disabled || formControl?.disabled}
            slotProps={merge<
              DatePickerSlotsComponentsProps<Date>,
              DatePickerSlotsComponentsProps<Date>
            >(slotProps || {}, {
              textField: {
                error: !!error,
                helperText: !!error ? datePickerErr : undefined,
              },
            })}
            {...datePickerProps}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default FormDatePicker;
